import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import classes from './SearchInput.module.scss';
import { SearchInputProps } from './SearchInput.types';

export const SearchInput = ({ search, getInputProps, onChange, onFocus, color }: SearchInputProps) => {
  const handleOnChange = (search: string) => onChange(search);

  return (
    <div className={classes.inputWrapper}>
      <div className={classes.inputIcon}>
        <FontAwesomeIcon icon={faSearch} color={color} />
      </div>
      <input
        {...getInputProps({
          className: classes.input,
          onFocus: () => onFocus(),
          placeholder: 'Search by name',
          value: search,
          onChange: (e: any) => handleOnChange(e.target.value),
          type: 'text',
          name: 'search',
          'aria-label': 'search-input',
          'aria-autocomplete': 'list',
          // 'aria-controls': 'search-menu',
          title: 'Search',
          style: { borderColor: color },
        })}
      />
    </div>
  );
};
