const disabledRecentClipsInstitutionIds = process.env.NEXT_PUBLIC_RECENT_CLIPS_DISABLED_INSTITUTIONS;

const amazonPrimeEnableInstitutionIds = process.env.NEXT_PUBLIC_AMAZON_PRIME_INSTITUTIONS;

const aluminatiSignShow = process.env.NEXT_PUBLIC_ALUMINATI_SIGNIN_SHOW;

export const isInstitutionHaveDisableRecentClips = (institutionId: string | null) => {
  if (disabledRecentClipsInstitutionIds) {
    const institutionList = disabledRecentClipsInstitutionIds.split(',');
    const disableRecentClips = institutionList.filter((item) => item === institutionId);

    if (disableRecentClips.length > 0) {
      return true;
    }
  }

  return false;
};

export const isAmazonPrimeEnableInstitution = (institutionId: string | null) => {
  if (amazonPrimeEnableInstitutionIds) {
    const institutionList = amazonPrimeEnableInstitutionIds.split(',');
    const enableInstitutions = institutionList.filter((item) => item === institutionId);

    if (enableInstitutions.length > 0) {
      return true;
    }
  }

  return false;
};

export const isAluminatiSignInShow = (domain: string | null) => {
  if (aluminatiSignShow) {
    const domainList = aluminatiSignShow.split(',');
    const enableAluminatiSignIn = domainList.filter((item) => item === domain);

    if (enableAluminatiSignIn.length > 0) {
      return true;
    }
  }

  return false;
};
